import { endpoints } from "constant";
import { apiAction } from "store/middlewares/api.middleware.helper";
import { EMethods } from "store/store.interface";
import {
  GET_SHIPMENT_SERVICES,
  SET_SHIPMENT_SERVICES,
} from "store/store.types";
import { Dispatch } from "redux";
import { SLOT_API_CONFIG } from "store/middlewares/api.config";

export const getShipmentServices = () => (dispatch: Dispatch<any>) => {
  const endpoint = SLOT_API_CONFIG.baseURL + endpoints.SHIPMENT_SERVICES;
  dispatch(
    apiAction({
      label: GET_SHIPMENT_SERVICES,
      method: EMethods.GET,
      url: endpoint,
      isAbsoluteUrl: true,
      data: {},
      withToken: true,
      onSuccess: (response) => dispatch({
        type: SET_SHIPMENT_SERVICES,
        payload: response,
      }),
    })
  );
};
