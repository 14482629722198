import React, { FC, useCallback, useMemo, useState } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { EPromotionTypes, IState } from "store/store.interface";
import { ValueWrapper, TextWrapper } from "./Summary.style";
import { isDate } from "date-fns";
import { format } from "date-fns-tz";
import { clearApi } from "store/actions/api.actions";
import { PROMOTION_CREATE,PROMOTION_UPDATE_STATUS, RESOURCE_GET_BY_ID } from "store/store.types";
import BadgePreview from "../CustomFields/BadgePreview";
import { Alert, Box, Button, Input, Link, Text, VirtuazlizedList } from "common";
import { useApiInfo, useTimeZoneSupport } from "hooks";
import { getResourceById } from "store/actions/promotion.actions";
import { ExclusionLabel } from "store/reducers/promotion.reducers";
import { downloadCSV } from "utils/app.utils";

interface IProps {
  formValues: any;
  error?: string;
  onEditBasicInfo?: () => void;
  onEditRestrictions?: () => void;
  isView?: boolean;
}

const ValueBlock: React.FC<{ name: string; value: string | string[] | any; fieldName?: string }> = ({
  name,
  value,
  fieldName = '',
  ...rest
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };  
  const updatedList = useMemo(() => {
    if (value && Array.isArray(value)) {
      if (searchTerm) { return value.filter((item) => item.indexOf(searchTerm) === 0); }
    }
    return value;
  }, [value, searchTerm]);

  return (
    <ValueWrapper {...rest}>
      <Text
        whiteSpace="nowrap"
        fontSize={["14px", "16px", "16px", "16px"]}
        fontWeight={600}
      >
        {name}
      </Text>
      {Array.isArray(value) ? (
        <Box my="8px" gridGap="10px">
          {value.length > 5 && (
            <Input
              size="tiny"
              value={searchTerm}
              label="Search ..."
              variant="outlined"
              onChange={onChangeSearch}
              testId="summary-virtualizedlist-search"
            />
          )}
          {value.length > 5 ? (
            <VirtuazlizedList
              itemData={updatedList}
              height={150}
              width={250}
              itemSize={30}
              itemCount={updatedList.length}
              containerStyles={{
                backgroundColor: "#f7f7f9",
                height: 150,
                width: "100%",
              }}
            />
          ) : (
            value.map((single,index) => <TextWrapper key={`Test${index}`}>{single}</TextWrapper>)
          )}
          {(['sku', 'bin', 'category', 'brand'].includes(fieldName)) && (
            <Button
              variant="outlined"
              color="primary"
              round
              width="auto"
              data-testid={`downloadFile-${fieldName}`}
              onClick={() =>
                downloadCSV(
                  value.reduce((accumulator, ele) => accumulator + "\n" + ele),
                  `${name}.csv`
                )
              }
            >
              Download
            </Button>
          )}
        </Box>
      ) : (
        <Box>
          <TextWrapper>{value}</TextWrapper>
        </Box>
      )}
    </ValueWrapper>
  );
};

const Summary: FC<IProps> = ({
  error,
  formValues,
  onEditBasicInfo,
  onEditRestrictions,
  isView,
}) => {
  const {
    badgeStylesMapping = {},
    summaryMapping = {},
    selectedBrands = [],
    selectedCategories = [],
    selectOptions
  } = useSelector((state: IState) => ({
    ...state.config,
  }));
  const { dateToTimeZone, timeZone } = useTimeZoneSupport();
  const dispatch = useDispatch();
  const{ isLoading }= useApiInfo(RESOURCE_GET_BY_ID)
  const downloadFileHandler = useCallback((id:string) => {
    dispatch(getResourceById(id))
  }, [dispatch]);

  const oncloseNotification = () => {
    dispatch(clearApi(PROMOTION_CREATE));
    dispatch(clearApi(PROMOTION_UPDATE_STATUS));
  };

  const { restrictions = {}, ...rest } = useMemo(
    () => formValues,
    [formValues]
  );

  // get Badge Preview details
  const badgePreviewProps = useMemo(() => {
    let normalText = "";
    let boldText = "";
    let color = "";
    let bgColor = "";
    let placement = "";
    if (formValues && formValues.type === EPromotionTypes.BADGE_CAMPAIGN) {
      const { badge } = formValues;
      const { text, type: badgeType, placement: badgePlacement } = badge || {};
      if (text) {
        const { en } = text;
        const { normalText: normal, boldText: bold } = en || {};
        if (normal || bold) {
          normalText = normal || "";
          boldText = bold || "";
        }
      }
      if (badgeType) {
        const { bgColor: backgroundColor, color: fontColor } =
          badgeStylesMapping[badgeType] || {};
        color = fontColor || "";
        bgColor = backgroundColor || "";
      }
      if (badgePlacement) {
        placement = badgePlacement === "AREA-A" ? "top" : "bottom";
      }
    }
    return { normalText, boldText, color, bgColor, placement };
  }, [formValues, badgeStylesMapping]);

  const renderSummaryBlock = useCallback(
    (
      key: any,
      index: string,
      summaryMappingObject: any,
      summaryObject: any,
      inclusiveFlag?:boolean
    ): any => {
      let name = summaryMappingObject[key];
      if (key === "value" && summaryObject.type === "FREEDELIVERY") {
        name = "Discount Percentage (%)";
      }
      const summaryValue = summaryObject ? summaryObject[key] : undefined;
      if (name && typeof summaryValue !== "undefined") {
        let value;
        if (typeof name === "string") {
          if (
            summaryValue &&
            (key === "startDate" || key === "endDate") &&
            isDate(new Date(summaryValue))
          ) {
            value = format(
              dateToTimeZone(summaryValue) as Date,
              "MMMM d','yyyy 'at' hh:mm:ss' 'aa XX",
              { timeZone }
            );
          } else if (
            summaryValue &&
            Array.isArray(summaryValue) &&
            summaryValue.length > 0
          ) {
            if (key === "brand" || key === "category") {
              const list =
                key === "brand" ? selectedBrands : selectedCategories;
              value = summaryValue.map(
                (singleValue) =>
                  (list.filter((key) => key.value === singleValue)[0] || {})
                    .label || singleValue
              );
            } else {
              value = summaryValue;
            }
          } else if (key === "shipmentType") {
            if (summaryValue === "STANDARD") {
              value = "Standard E-food";
            } else if (summaryValue === "DEFAULT") {
              value = "Standard Non-Food";
            } else value = summaryValue;
          } else if (
            key !== "customerEmailGroup" &&
            (typeof summaryValue === "string" ||
              typeof summaryValue === "number")
          ) {
            value = `${summaryValue}`;
          } else if (typeof summaryValue === "boolean") {
            value = summaryValue ? "Yes" : "No";
          }
          if (key === "brand" || key === "category" || key === "sku") {
            if (
              !inclusiveFlag &&
              (Array.isArray(summaryValue) ||
                summaryValue.op === ExclusionLabel.in)
            )
              value = summaryValue.value || summaryValue;
            else if (
              inclusiveFlag === true &&
              summaryValue.op === ExclusionLabel.notIn
            )
              value = summaryValue.value;
            else value = null;
          }
          if(key === "isCustomerFirstOrder"){
            let userValue = "";
            if(value ==="Yes"){
              userValue="New User"
            }else{
              if(value ==="No"){
                userValue="Returning Users"
              }else{
                userValue="All Users"
              }
            }
            return <ValueBlock name={name} value={userValue} key={index} />;
          }
          if(value === "INCREMENTAL") {
            const values = rest.discount?.rules?.metaData?.discountRanges;
            let maxAmount: number = 0;
            values?.forEach((res: any) => {
            if(res?.discount?.maxAmount) {
              maxAmount = res.discount.maxAmount;
            }
          });
            return (
              <>
                <ValueBlock name={'Incremental Discount'} value={
                  <Box gridGap="10px" style={{width: '100%', textAlign:"center", minWidth:"250px" }}>
                    <Box style={{ display:"grid", gridTemplateColumns: '1fr 1fr 1fr' }}>
                      <h3 style={{margin:"0", fontSize:"14px", paddingLeft:"15px" }}> Min Order Value </h3>
                      <h3 style={{margin:"0", fontSize:"14px", paddingLeft:"15px"}}> Max Order Value </h3>
                      <h3 style={{margin:"0", fontSize:"14px", paddingLeft:"15px"}}> Discount Value</h3>
                    </Box>
                    {values?.map((data: any, index: number) => (
                      <Box key={`inc_disc_${index}`} style={{ display:"grid", gridTemplateColumns: '1fr 1fr 1fr' }}>
                        <Box style={{ paddingLeft:"15px" }}> {data.slot[0]} </Box>
                        <Box style={{ paddingLeft:"15px" }}> {data.slot[1]} </Box>
                        <Box style={{ paddingLeft:"15px" }}> {data.discount?.value} </Box>
                      </Box>
                    ))}
                  </Box>
                } key={index}/>
                {maxAmount > 0 &&
                  <ValueBlock name={"Max Discount Amount"} value={maxAmount} key={index} />
                }
              </>
            );
          }
          if (value) {
            return <ValueBlock name={name} value={value} key={index} fieldName={key} />;
          } else {
            if(key === 'customerEmailGroup') {
              return <ValueBlock name={name} value={
                <Button 
                variant="outlined"
                color="primary"
                round
                width="auto" 
                disabled={isLoading}
                data-testid='downloadFile'
                onClick={() => downloadFileHandler(summaryValue)}> Download </Button>
              } key={index} />;
            }
          }
        } else if (typeof name === "object" && Object.keys(name).length > 0) {
          return Object.keys(name).map((newKey, newObjIndex) =>
            renderSummaryBlock(
              newKey,
              `${index}_${newObjIndex}_${newKey}`,
              name,
              summaryValue
            )
          );
        }
      }
      return null;
    },
    [selectedBrands, selectOptions, selectedCategories, dateToTimeZone, timeZone, downloadFileHandler,isLoading,rest.discount]
  );

  const orderRestrictionArray = ['brand','category','sku','sellerId','shipmentType', 'minOrderValue'];
  const orderRestrictionExclusionArray = ['brand','category','sku'];
  const userRestrictionArray = ['isCustomerFirstOrder','customerEmailGroup','numberOfUsagePerCustomer','validity'];
  const campaignRestrictionArray = ['numberOfUsage','budget','isRetail']
  const renderRestrictionHeaders = useCallback(
    (
      title: string,
      restrictionArray: string[],
      summaryMapping: any,
      restrictions: any,
      inclusiveFlag?: boolean
    ): any => {
      const returnValue = restrictionArray.map((key, index) =>
        renderSummaryBlock(
          key,
          `${key}_${index}_basic`,
          summaryMapping,
          restrictions,
          inclusiveFlag
        )
      );
      const component = returnValue.filter((item) => item !== null);
      if (component.length) {
        return (
          <Box my="4px" data-testid="restrictions-container">
            <Text fontSize="16px" fontWeight={700} my={1}>
              {title}
            </Text>
            {component}
          </Box>
        );
      }
    },
    [renderSummaryBlock]
  );
  return (
    <Layout
      title={
        isView
          ? "Promotion details summary"
          : "Lets check and publish your promotion"
      }
      header="Summary"
      isSummary={true}
      status={rest.status}
      object={rest.object}
      id={rest.id}
    >
      {error && (
        <Alert
          padding="5px 15px"
          width="100%"
          variant="filled"
          severity="error"
          margin="15px 0"
          onClick={oncloseNotification}
        >
          {error || "Something went wrong"}
        </Alert>
      )}
      <Box width="100%" data-testid="summary-container">
        <Box
          display="flex"
          justifyContent="space-between"
          my="10px"
          alignItems="center"
        >
          <Text fontSize="18px" fontWeight={700}>
            Basic information
          </Text>
          {onEditBasicInfo && (
            <Link
              variant="default"
              color="primary"
              onClick={onEditBasicInfo}
              fontWeight={500}
              data-testid="edit-basic-button"
            >
              Edit
            </Link>
          )}
        </Box>
        <Box
          gridGap="15px"
          my="15px"
          width="100%"
          data-testid="basic-info-container"
          gridTemplateColumns={["1fr", "1fr", "1fr", "1fr 1fr"]}
        >
          {Object.keys(summaryMapping).map((key, index) =>
            renderSummaryBlock(
              key,
              `${key}_${index}_basic`,
              summaryMapping,
              rest
            )
          )}
        </Box>
        {Object.keys(restrictions).length > 0 && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              my="10px"
              data-testid="restrictions-header"
            >
              <Text marginTop="15px" fontSize="18px" fontWeight={700}>
                Restrictions
              </Text>
              {onEditRestrictions && (
                <Link
                  onClick={onEditRestrictions}
                  variant="default"
                  color="primary"
                  fontWeight={500}
                  data-testid="edit-restrictions-button"
                >
                  Edit
                </Link>
              )}
            </Box>
            <Box
              gridGap="15px"
              gridTemplateColumns={["1fr", "1fr", "1fr", "1fr 1fr"]}
            >
              {renderRestrictionHeaders(
                "BIN Restrictions",
                ["bin"],
                summaryMapping,
                restrictions
              )}
            </Box>
            <Box
              gridGap="15px"
              gridTemplateColumns={["1fr", "1fr", "1fr", "1fr 1fr"]}
            >
              {renderRestrictionHeaders(
                "Order Restrictions - Inclusion",
                orderRestrictionArray,
                summaryMapping,
                restrictions,
                false
              )}
              {renderRestrictionHeaders(
                "Order Restrictions - Exclusion",
                orderRestrictionExclusionArray,
                summaryMapping,
                restrictions,
                true
              )}
            </Box>
            <Box
              gridGap="15px"
              gridTemplateColumns={["1fr", "1fr", "1fr", "1fr 1fr"]}
            >
              {renderRestrictionHeaders(
                "User Restrictions",
                userRestrictionArray,
                summaryMapping,
                restrictions
              )}
              {renderRestrictionHeaders(
                "Campaign Restrictions",
                campaignRestrictionArray,
                summaryMapping,
                restrictions
              )}
            </Box>
          </>
        )}
      </Box>
      {formValues.type === EPromotionTypes.BADGE_CAMPAIGN && (
        <Box display="flex" flexDirection="column">
          <Text
            marginTop="10px"
            marginBottom="15px"
            fontSize="18px"
            fontWeight={700}
          >
            Badge Preview
          </Text>
          <BadgePreview
            width="200px"
            height="300px"
            fontSize="10px"
            withMargin={false}
            {...badgePreviewProps}
          />
        </Box>
      )}
    </Layout>
  );
};

export default Summary;

