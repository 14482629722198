import { IAction } from "store/store.interface";
import { SET_SHIPMENT_SERVICES } from "store/store.types";

export const SHIPMENT_SERVICES_INITIAL_STATE: IShipmentTypes[] = [];
export interface IShipmentTypes {
  serviceCode?: number;
  name?: string;
  active?: boolean;
  displayName?: string;
  deliveryType?: string;
  rank?: number;
}
const shipmentServicesReducer = (
  state: IShipmentTypes[] = SHIPMENT_SERVICES_INITIAL_STATE,
  action: IAction
) => {
  switch (action.type) {
    case SET_SHIPMENT_SERVICES: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default shipmentServicesReducer;
