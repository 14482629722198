export const namespace = "http://maf/promotion-engine";

export const endpoints = {
  LOGIN: "/users/login",
  USER_INFO: "/users/info",
  REFRESH_TOKEN: "/users/refresh-token",
  CREATE_CAMPAIGN: "/campaigns",
  FETCH_CAMPAIGNS: "/campaigns",
  SEARCH_CAMPAIGNS: "/campaigns/search",
  UPDATE_CAMPAIGN_STATUS: "/campaigns/{{object}}/{{id}}/status",
  GET_CAMPAGIN_BY_ID: "/campaigns/{{object}}/{{id}}",
  GET_CAMPAGIN_BY_ID_V2: "/campaigns/v2/{{object}}/{{id}}",
  UPDATE_CAMPAGIN_V2: "/campaigns/v2/{{object}}/{{id}}",
  GET_REPORT: "/campaigns/{{object}}/{{id}}/reports",
  EXTEND_CAMPAIGN: "/campaigns/{{object}}/{{id}}",
  DOWNLOAD_CSV: "/campaigns/coupon/{{id}}/csv",
  GET_RESOURCE_BY_ID: "/restrictions/{{id}}/download",
  CREATE_RESOURCE: "/resources",
  CHANGE_PASSWORD:"/users/password",
  GET_USERS_LIST:"/users?page={{page}}",
  DELETE_USER_BY_ID:"/users/{{id}}",
  ADD_NEW_USER :'/users',
  UPDATE_USER_BY_ID:"/users/{{id}}",
  SHIPMENT_SERVICES:"/api/v2/services"
};

export const staticApiEndpoints = {
  CONFIG: "/content/config/basic",
  FIELDS: "/content/promotion/{{type}}",
};

export const absoluteUrls = {
  categories:
    "https://promotion-engine-search.search.windows.net/indexes/category/docs",
  brands:
    "https://promotion-engine-search.search.windows.net/indexes/brand/docs",
};

export const API_KEY_AZURE_SEARCH =  process.env.REACT_APP_AZURE_KEY_SEARCH
