import { IAPiState } from "./reducers/api.reducers";
import { IAuthState } from "./reducers/auth.reducers";
import { IConfig } from "./reducers/config.reducers";
import { IPromotion } from "./reducers/promotion.reducers";
import { IShipmentTypes } from "./reducers/shipmentServices.reducers";
import { IUsers } from "./reducers/users.reducers";
export enum EMethods {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
  PUT = "PUT",
  PATCH = "PATCH",
}

export const restrictionNoDisableFields = ["numberOfUsage", "budget"]

export interface IAction<T = any> {
  type: string;
  payload?: T;
}

export type FilterNameType =
  | "startDate"
  | "endDate"
  | "Object"
  | "Status"
  | "type";

export interface IFilterOption {
  name: FilterNameType;
  value: string;
}
export interface IFilter {
  name: FilterNameType;
  value: string[];
}

export type IApiMiddlewareAction = {
  type: string;
  payload: IPayload;
};

export type INetworkRequest = {
  forFileSend?: boolean;
  headers?: object; // Override headers
  method: EMethods;
  url: string;
  data: object;
  isAbsoluteUrl?: boolean;
  isStatic?: boolean;
  withStoreId?: boolean;
  withToken?: boolean;
  requestConfig?: any;
};

export type IPayload = INetworkRequest & {
  label: string; // Module/feature specific label
  onSuccess?: (data: any) => void;
  onFailure?: (errorMessage: string) => any;
  onFinally?: () => void;
};

export enum EPromotionTypes {
  COUPON = "COUPON",
  DISCOUNT_COUPON = "DISCOUNT_COUPON",
  BIN_CAMPAIGN = "BIN_CAMPAIGN",
  BADGE_CAMPAIGN = "BADGE_CAMPAIGN",
  BULK_COUPON = "BULK_COUPON",
  BMSM_CAMPAIGN = "BMSM_CAMPAIGN",
  SAMPLE_CAMPAIGN = 'SAMPLE_CAMPAIGN',
}

export enum EDiscountTypes {
  INCREMENTAL = "INCREMENTAL",
  DISCOUNT = "DISCOUNT",
  FIXED = "FIXED",
  FREE_DELIVERY = 'FREEDELIVERY'
}

export interface IState {
  api: IAPiState;
  auth: IAuthState;
  config: IConfig;
  promotion: IPromotion;
  users:IUsers;
  shipmentServices: IShipmentTypes[]; 
}
